/** xhr-Modul zum Senden der Formulardaten */
import xhr from 'xhr'
import selectAll from './select-all'
import notifier from '../vendor/notifier'

/**
 * Erstellt Eingabeobjekt aus FormData
 * @param  {Object} formData Formulardaten
 * @return {Object}          Eingabeobjekt
 */
function generateDataObject (form) {
  let data = {}
  let formElements = selectAll('[name]', form)
  formElements.map(el => {
    if ((el.getAttribute('type') !== 'radio' && el.getAttribute('type') !== 'checkbox') || (el.getAttribute('type') === 'radio' && el.checked === true) || (el.getAttribute('type') === 'checkbox' && el.checked === true)) {
      let name = el.getAttribute('name')
      if (name !== undefined && name !== null && name !== 'g-recaptcha-response' && name !== 'undefined') {
        data[name] = {
          value: el.value,
          element: el,
          required: el.hasAttribute('required')
        }

        if (el.tagName === 'INPUT') {
          data[name].type = el.getAttribute('type') || 'text'
        }

        if (el.hasAttribute('data-verify')) {
          data[name].regex = el.getAttribute('data-verify')
        }

        data[name].name = el.getAttribute('data-name') || el.getAttribute('placeholder')
      }
    }
  })

  return data
}

/**
 * Setzt Attribute ob alle Eingaben valide sind
 * @param  {Object} data Eingabeobjekt
 * @return {Object}      Eingabeobjekt mit Validitätsattribut
 */
function verifyData (data) {
  Object.keys(data).map(key => {
    data[key].valid = verifyValue(data[key])
  })
  return data
}

/**
 * Validiert String anhand von Regulärem Ausdruck
 * @param  {RegExp} re    Regulärer Ausdruck
 * @param  {String} value Eingabestring
 * @return {Boolean}      Wert ob String valide ist
 */
function verifyRegex (re, value) {
  return re.test(value)
}

/**
 * Validierung einer Eingabe anhand von Typ oder Regulärem Ausdruck
 * @param  {Object} input Eingabeobjekt
 * @return {Boolean}      Wert ob Eingabe valide
 */
function verifyValue (input) {
  if (!input.required) {
    return true
  }

  if (input.value === '') {
    return false
  }

  if (input.type === 'email') {
    return verifyRegex(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, input.value)
  }

  if (input.type === 'number') {
    return verifyRegex(/(?:\d*\.)?\d+/g, input.value)
  }

  if (input.regex) {
    return verifyRegex(new RegExp(input.regex), input.value)
  }

  return true
}

/**
 * Überprüfen ob alle Werte valide sind und Daten absenden
 * @param  {Object}   data     Formulardaten
 * @param  {Function} callback Callback Funktion zur Auswertung der Server Response
 */
function sendData (data, callback) {
  let invalid = []
  let invalidElements = []
  Object.keys(data).map(key => {
    if (!data[key].valid) {
      invalid.push(data[key].name)
      invalidElements.push(data[key].element)
    }
  })
  if (invalid.length > 0) {
    callback({// eslint-disable-line
      message: `Bitte überprüfe folgende Eingabefelder: ${invalid}`,
      elements: invalidElements,
      status: 'error'
    })
    return false
  } else if (!('contact-datenschutz' in data)) {
    callback({
      message: 'Bitte Datenschutz bestätigen.',
      status: 'error'
    })
  } else {
    xhr({
      body: JSON.stringify(data),
      uri: 'ajax.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, callback)
  }
}

function showStatus (s, form) {
  if (s.status !== '') {
    if (s.titel === undefined) {
      s.titel = ''
    }
    notifier.show(s.titel, s.message, s.status, '', 4000)
    if (s.elements.length > 0) {
      s.elements.map(el => el.classList.add('border-error'))
    }
  }
}

/**
 * Daten laden und senden und Ergebnis auswerten
 * @param  {Object} e Dom Event
 */
export function submit (e) {
  if (e.target.getAttribute('type') === 'submit' && !e.target.classList.contains('no-auto-post')) {
    e.preventDefault(e)

    let form = e.target.form

    let dataObject = verifyData(generateDataObject(form))

    sendData(dataObject, (err, res, body) => {
      if (err) {
        console.log(err)
        showStatus(err, form)
        return false
      } else {
        let data = JSON.parse(body)
        if (data.status === 'success') {
          clearForm(dataObject)
        }
        showStatus(JSON.parse(body), form)
      }
    })
  }
}

function clearForm (data) {
  Object.keys(data).map(key => {
    let type = data[key].element.type
    if (type === 'text' || type === 'email' || type === 'tel' || type === 'textarea') {
      data[key].element.value = ''
    }
    if (type === 'checkbox' || type === 'radio') {
      data[key].element.checked = false
    }
    if (type === 'select') {
      data[key].element.value = ''
    }
  })
}

/**
 * Einstieg für automatische Formularvalidierung und senden der Daten
 */
export default function formSubmit () {
  let forms = selectAll('form')
  forms.map(form => form.addEventListener('click', submit))
}
